import { add, compareAsc, isBefore } from "date-fns";
import { formatCurrency } from "helpers/helpers";
import type { CancellationPolicyRulePerLineItem } from "helpers/server/cancellations";
import { getTotalForSessionItemsFromActivityLineItems } from "helpers/session-pass";
import type { Activity, AddOnWithActivityAttendee } from "types/model/activity";
import type { ActivityGroup } from "types/model/activity-group";
import type { Booking } from "types/model/booking";
import type {
  AccountCreditRefund,
  AvailableStripeRefund,
  CancellationPolicy,
  CancellationPolicyRule,
  CancellationWindowValue,
  ExistingCreditItemUsed,
  PassCreditRefundItem,
  PassDebitRefundStep,
  RefundOption,
  StripeRefund
} from "types/model/cancellation";
import { RefundMethod } from "types/model/cancellation";
import type {
  CartItemByTicket,
  CartLineItemWithTicketItem
} from "types/model/cart";
import type { Client } from "types/model/client";
import type { DiscountLineItemToAdjust } from "types/model/discount-rule";
import type { LineItem } from "types/model/line-item";
import { LineItemType } from "types/model/line-item";
import type { PaymentTransaction } from "types/model/payment";
import { PaymentTransactionType } from "types/model/payment";

export const getItemsToCancel = (
  ticketItems: CartItemByTicket[],
  lineItemsToCancelIds: string[]
) => {
  const itemsToCancel: CartLineItemWithTicketItem[] = ticketItems.reduce(
    (acc, ticketItem) => {
      const lineItems = ticketItem.lineItems
        .filter(lineItem => lineItemsToCancelIds.includes(lineItem._id))
        .map(lineItem => ({
          ...lineItem,
          ticketItem
        }));
      return [...acc, ...lineItems];
    },
    [] as CartLineItemWithTicketItem[]
  );

  return itemsToCancel;
};

export const getAddOnItemsToCancel = (
  ticketItems: CartItemByTicket[],
  lineItemsToCancelIds: string[]
) => {
  const addOnItemsToCancel: AddOnWithActivityAttendee[] = ticketItems.reduce(
    (acc, ticketItem) => {
      const addOnItems = ticketItem.addOns
        .filter(
          addOn =>
            lineItemsToCancelIds.includes(addOn.lineItemId || "") &&
            !lineItemsToCancelIds.includes(addOn.parentId || "")
        )
        .map(addOn => {
          const attendee = ticketItem.lineItems.find(
            lineItem => lineItem.attendee
          )?.attendee;

          const activity = addOn.activity || ticketItem.activities[0];

          return {
            ...addOn,
            attendee,
            activity
          };
        });

      return [...acc, ...addOnItems];
    },
    [] as AddOnWithActivityAttendee[]
  );

  return addOnItemsToCancel;
};

interface GetStripeRefundAmountParams {
  lineItemsToCancel: LineItem<string, Activity<string>>[];
  discountLineItemsAdjustments: DiscountLineItemToAdjust[];
}

export const getStripeRefundAmountForUserCancellation = ({
  lineItemsToCancel,
  discountLineItemsAdjustments
}: GetStripeRefundAmountParams) => {
  const lineItemsCancelTotal = lineItemsToCancel.reduce((acc, lineItem) => {
    const lineItemTotal = lineItem.total;
    return acc + lineItemTotal;
  }, 0);

  const discountLineItemsAdjustmentsTotal = discountLineItemsAdjustments.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const refundAmount = lineItemsCancelTotal + discountLineItemsAdjustmentsTotal;

  return refundAmount;
};

export const getTotalPassCreditsToIssue = (
  itemsToCancel: CartLineItemWithTicketItem[]
) => {
  const passCreditsToIssue = itemsToCancel.reduce(
    (acc, item) =>
      (acc += item.ticketItem.activities.filter(
        activity => activity.enabled
      ).length),
    0
  );

  return passCreditsToIssue;
};

export const getCancellationPolicyForActivityGroup = (
  cancellationPolicies: CancellationPolicy<string[]>[],
  activityGroupId: string
): CancellationPolicy<string[]> => {
  const policyForActivityGroup = cancellationPolicies.find(
    cancellationPolicy =>
      cancellationPolicy.appliesToAllActivityGroups ||
      cancellationPolicy.activityGroups?.includes(activityGroupId)
  );

  return policyForActivityGroup as CancellationPolicy<string[]>;
};

interface StripeRefundsBreakdownItem {
  stripePaymentTransaction: PaymentTransaction;
  amountToRefund: number;
}

// TODO: This stuff is very similar to the `getRefundsToCreate` in helpers/server/payments
// can maybe try to combine them
export const getStripeRefundsBreakdown = (
  refundAmount: number,
  stripePaymentTransactions: PaymentTransaction[]
): StripeRefundsBreakdownItem[] => {
  const refundTransactions = stripePaymentTransactions.filter(
    paymentTransaction =>
      paymentTransaction.type === PaymentTransactionType.Refund
  );
  const chargeTransactions = stripePaymentTransactions.filter(
    paymentTransaction =>
      paymentTransaction.type === PaymentTransactionType.Charge
  );

  const stripeRefundsBreakdownData = chargeTransactions.reduce(
    (acc, chargeTransaction) => {
      if (acc.refundAmountRemaining <= 0) {
        return acc; // no need to use further transactions as we've already aportioned the refund amount down to 0
      }

      const refunds = refundTransactions.filter(
        refundTransaction =>
          refundTransaction.stripePaymentIntentId ===
          chargeTransaction.stripePaymentIntentId
      );

      const totalRefundAmount = refunds.reduce(
        (acc, refund) => acc + refund.amount,
        0
      );

      const maxAvailableRefundForTransaction =
        chargeTransaction.amount - totalRefundAmount;

      const refundAmountForTransaction = Math.min(
        acc.refundAmountRemaining,
        maxAvailableRefundForTransaction
      );
      if (refundAmountForTransaction <= 0) {
        return acc; // no funds available to issue refund for this transaction, move on to next one
      }

      acc.breakdownItems.push({
        stripePaymentTransaction: chargeTransaction,
        amountToRefund: refundAmountForTransaction
      });

      acc.refundAmountRemaining -= refundAmountForTransaction;

      return acc;
    },
    {
      breakdownItems: [] as StripeRefundsBreakdownItem[],
      refundAmountRemaining: refundAmount
    }
  );

  return stripeRefundsBreakdownData.breakdownItems;
};

export const generateRefundOptionTitle = (refundOption: RefundOption) => {
  const { passCredit, stripe, creditOnAccount } = refundOption;

  const passCreditTitle = passCredit.length
    ? passCredit
        .filter(pc => pc.passCredits > 0)
        .map(pc => `${pc.name} credits`)
        .join(", ")
    : "";

  const stripeTitle = stripe.length > 0 ? "Card" : "";
  const creditOnAccountTitle =
    creditOnAccount.length > 0 ? "Credit on Account" : "";

  const titles = [passCreditTitle, stripeTitle, creditOnAccountTitle].filter(
    title => title.length
  );

  if (titles.length > 1) {
    const lastTitle = titles.pop();
    return titles.join(", ") + " & " + lastTitle;
  } else if (titles.length === 1) {
    return titles[0];
  } else {
    return "No refund";
  }
};

export const getRefundMethodReadableName = (refundMethod: RefundMethod) => {
  switch (refundMethod) {
    case RefundMethod.Stripe:
      return "Stripe";
    case RefundMethod.AccountCredit:
      return "Account credit";
    default:
      return "Unknown";
  }
};

export const generateRefundOptionDescriptions = (
  refundOption: RefundOption,
  client: Client
): string[] => {
  const { passCredit, stripe, creditOnAccount } = refundOption;

  const stripeAmount = stripe.reduce((acc, item) => acc + item.amount, 0);
  const creditOnAccountAmount = creditOnAccount.reduce(
    (acc, item) => acc + item.amount,
    0
  );

  const passCreditDescriptions = passCredit
    .filter(pc => pc.passCredits > 0)
    .map(
      pc =>
        `${pc.passCredits} ${pc.name} credit${
          pc.passCredits > 1 ? "s" : ""
        } reissued`
    )
    .join(", ");

  const stripeDescription =
    stripeAmount > 0
      ? `Card refund of ${formatCurrency({
          rawAmount: stripeAmount,
          currency: client.currency
        })}`
      : "";
  const creditOnAccountDescription =
    creditOnAccountAmount > 0
      ? `Account credit of ${formatCurrency({
          rawAmount: creditOnAccountAmount,
          currency: client.currency
        })}`
      : "";

  const descriptions = [
    passCreditDescriptions,
    stripeDescription,
    creditOnAccountDescription
  ].filter(desc => desc.length);

  if (!descriptions.length) {
    descriptions.push("Cancel without refund");
  }

  return descriptions;
};

export const getAccountCreditItems = (
  creditOnAccountAmount: number,
  existingCreditItemsUsed: ExistingCreditItemUsed[],
  accountCreditExpiry?: Date
): AccountCreditRefund[] => {
  const existingCreditItemsUsedSorted = existingCreditItemsUsed.sort((a, b) => {
    if (!a.expires) return -1;
    if (!b.expires) return 1;

    return isBefore(a.expires, b.expires) ? 1 : -1;
  });

  const { items, remainingAmountToAssign } =
    existingCreditItemsUsedSorted.reduce(
      (acc, item) => {
        if (acc.remainingAmountToAssign > 0) {
          if (!item.expires || isBefore(new Date(), item.expires)) {
            acc.items.push({
              accountCreditItemId: item.accountCreditItemId,
              amount: Math.min(item.amount, acc.remainingAmountToAssign),
              isNewCreditItem: false,
              ...(item.expires && {
                expires: item.expires
              })
            });
          }
          acc.remainingAmountToAssign -= item.amount;
        }

        return acc;
      },
      { items: [], remainingAmountToAssign: creditOnAccountAmount } as {
        items: AccountCreditRefund[];
        remainingAmountToAssign: number;
      }
    );

  if (remainingAmountToAssign > 0) {
    items.push({
      amount: remainingAmountToAssign,
      isNewCreditItem: true,
      ...(accountCreditExpiry && {
        expires: accountCreditExpiry
      })
    } as AccountCreditRefund);
  }

  return items;
};

export const getPassCreditItems = (
  passDebitLineItem: LineItem,
  step: PassDebitRefundStep
): PassCreditRefundItem[] => {
  const expiresDates = (
    step.sessionPassTransactionsToCreateForRecredit || []
  ).map(transactionsToCreateForRecredit => ({
    passCredits: transactionsToCreateForRecredit.passCredits,
    expires: transactionsToCreateForRecredit.expires
  }));

  return [
    {
      passDebitLineItemId: passDebitLineItem._id,
      sessionPassId: passDebitLineItem.passDebitData?.sessionPass._id,
      name: passDebitLineItem.passDebitData?.sessionPass.name || "",
      passCredits: step.passCredits,
      amount: step.amount,
      expiresDates
    }
  ];
};

export const getStripeItems = (
  stripeRefundAmount: number,
  stipeRefundsAvailable: AvailableStripeRefund[]
): StripeRefund[] => {
  const stripeRefundAmountRemaining = stipeRefundsAvailable.reduce(
    (acc, item) => {
      if (acc.remainingAmountToAssign > 0) {
        acc.items.push({
          ...item,
          amount: Math.min(item.amount, acc.remainingAmountToAssign)
        });
        acc.remainingAmountToAssign -= item.amount;
      }

      return acc;
    },
    { items: [], remainingAmountToAssign: stripeRefundAmount } as {
      items: StripeRefund[];
      remainingAmountToAssign: number;
    }
  );

  return stripeRefundAmountRemaining.items;
};

export const getCancellationWindowReadableValue = (
  cancellationWindow: CancellationWindowValue
): string => {
  // Check if the amount is 1; if so, remove the trailing 's' from the unit
  const unit =
    cancellationWindow.amount === 1
      ? cancellationWindow.unit.toString().slice(0, -1)
      : cancellationWindow.unit.toString();

  // Return the readable string
  return `${cancellationWindow.amount} ${unit}`;
};

const getCancellationWindowAsMilliseconds = (
  cancellationWindow: CancellationWindowValue
): number => {
  const now = new Date();
  const cancellationWindowUnitAsString = cancellationWindow.unit.toString();
  const endDate = add(now, {
    [cancellationWindowUnitAsString]: cancellationWindow.amount
  });
  return endDate.valueOf() - now.valueOf();
};

export const compareCancellationPolicyRules = (
  a: CancellationPolicyRule,
  b: CancellationPolicyRule
) => {
  const aDuration = getCancellationWindowAsMilliseconds(a.cancellationWindow);
  const bDuration = getCancellationWindowAsMilliseconds(b.cancellationWindow);
  return compareAsc(bDuration, aDuration); // Swap aDuration and bDuration to sort from shortest to longest
};

export const getCancellationPolicyRuleForLineItem = (
  lineItem: LineItem<string | ActivityGroup, Activity<string>>,
  cancellationPolicy: CancellationPolicy
): CancellationPolicyRule | null => {
  // TODO: Ensure line items are definitely sorted by date
  const startDate = new Date(lineItem.activities[0].date.start);
  const now = new Date();

  if (isBefore(startDate, now)) {
    return null;
  }

  for (const rule of cancellationPolicy.rules) {
    const { amount, unit } = rule.cancellationWindow;
    const ruleEndDate = add(now, { [unit]: amount });

    if (!isBefore(startDate, ruleEndDate)) {
      return rule;
    }
  }

  return null;
};

export const getRefundMethodsConfigHelpText = (
  hasStripePaymentsEnabled: boolean,
  isClientPremiumPlanOrHigher: boolean
) => {
  const noStripeMsg =
    "Stripe cannot be selected as it is not currently an enabled payment method.";
  const stripeEnabledMsg =
    "Stripe can be selected as a refund method and will be available when this was used as the original payment method for the booking.";
  const noCreditMsg =
    "Account credit cannot be selected as it is only available on the Premium plan.";
  const noRefundOptionsMsg =
    "If no refund methods are available, users will be unable to make cancellations online.";

  if (!hasStripePaymentsEnabled && !isClientPremiumPlanOrHigher) {
    return `${noStripeMsg} ${noCreditMsg} ${noRefundOptionsMsg}`;
  } else if (hasStripePaymentsEnabled && !isClientPremiumPlanOrHigher) {
    return `${stripeEnabledMsg} ${noCreditMsg} ${noRefundOptionsMsg}`;
  } else if (!hasStripePaymentsEnabled && isClientPremiumPlanOrHigher) {
    return `${noStripeMsg} ${noRefundOptionsMsg}`;
  } else if (hasStripePaymentsEnabled && isClientPremiumPlanOrHigher) {
    return `${stripeEnabledMsg} ${noRefundOptionsMsg}`;
  }
};

interface GetPassDebitRefundsV2Params {
  passDebitLineItem: LineItem;
  booking: Booking;
  lineItemsToCancel: LineItem<string, Activity<string>>[];
  minimumPassDebitRefundCreditAmount: number;
}

interface PassCreditRefundStepsData {
  steps: PassDebitRefundStep[];
  trackingAmount: number;
}

export const getPassDebitRefundsSteps = ({
  passDebitLineItem,
  booking,
  lineItemsToCancel,
  minimumPassDebitRefundCreditAmount
}: GetPassDebitRefundsV2Params): PassDebitRefundStep[] => {
  const lineItemsToCancelSorted = lineItemsToCancel.sort(
    (a, b) => b.total - a.total
  );

  const lineItemsToCancelIds = lineItemsToCancelSorted
    .map(lineItem => lineItem._id)
    .toString();

  const passDebitLineItemCreditUsage =
    passDebitLineItem.passDebitData?.creditUsage || 0;

  const allLineItemsPossiblyPaidWithPassCredit = booking.lineItems.filter(
    lineItem =>
      lineItem.type === LineItemType.Activity &&
      !lineItem.cancelled &&
      passDebitLineItem.passDebitData?.activityLineItemsCreditUsedFor.includes(
        lineItem._id
      )
  );

  const lineItemsPossiblyPaidWithPassCreditBeingCancelled =
    allLineItemsPossiblyPaidWithPassCredit.filter(lineItem =>
      lineItemsToCancelIds.includes(lineItem._id)
    );

  const totalsForLineItemsPossiblyPaidWithPassCreditBeingCancelled =
    getTotalForSessionItemsFromActivityLineItems(
      lineItemsPossiblyPaidWithPassCreditBeingCancelled
    );

  const passCreditRefundStepsData =
    totalsForLineItemsPossiblyPaidWithPassCreditBeingCancelled
      .sort((a, b) => (a.total < b.total ? 1 : -1))
      .reduce(
        (acc: PassCreditRefundStepsData, item, idx) => {
          if (idx + 1 > passDebitLineItemCreditUsage) {
            return acc;
          }

          acc.trackingAmount += item.total;
          acc.steps.push({
            passCredits: idx + 1,
            amount: acc.trackingAmount
          });

          return acc;
        },
        { steps: [{ passCredits: 0, amount: 0 }], trackingAmount: 0 }
      );

  const passCreditRefundStepsWithMinRefundTotal =
    passCreditRefundStepsData.steps.filter(
      step => step.passCredits >= minimumPassDebitRefundCreditAmount
    );

  return passCreditRefundStepsWithMinRefundTotal;
};

interface GenerateRefundOptionsParams {
  amountToBeRefundedBeforeFeesAdjustments: number;
  bookingBalance: number;
  passDebitLineItem: LineItem;
  passDebitRefundSteps: PassDebitRefundStep[];
  stripeRefundsAvailable: AvailableStripeRefund[];
  existingCreditItemsUsed: ExistingCreditItemUsed[];
  canDoStripeRefund: boolean;
  canDoAccountCreditRefund: boolean;
  accountCreditExpiry?: Date;
}

export const generateRefundOptions = ({
  amountToBeRefundedBeforeFeesAdjustments,
  bookingBalance,
  passDebitLineItem,
  passDebitRefundSteps,
  stripeRefundsAvailable,
  existingCreditItemsUsed,
  canDoStripeRefund,
  canDoAccountCreditRefund,
  accountCreditExpiry
}: GenerateRefundOptionsParams): RefundOption[] => {
  const refundOptions: RefundOption[] = [];

  const totalStripeRefundsAvailableAmount = canDoStripeRefund
    ? stripeRefundsAvailable.reduce((total, item) => total + item.amount, 0)
    : 0;

  for (const step of passDebitRefundSteps) {
    const passCreditAmount = step.amount;

    const discountAdjustment = step.discountAdjustment || 0;
    const passDebitAdjustment = step.passDebitAdjustment || 0;

    const amountToBeRefundedAfterAdjustments =
      amountToBeRefundedBeforeFeesAdjustments -
      passCreditAmount +
      discountAdjustment +
      passDebitAdjustment;

    const feeAmount = Math.min(
      step.feeAmount || 0,
      amountToBeRefundedAfterAdjustments
    );

    const totalAmountWithFees =
      amountToBeRefundedBeforeFeesAdjustments - feeAmount;

    const remainingAmount =
      totalAmountWithFees -
      passCreditAmount +
      discountAdjustment +
      passDebitAdjustment -
      bookingBalance;

    if (remainingAmount < 0) {
      // remaining amount is negative, so we can't refund this amount
      continue;
    }

    const maxStripeRefund = Math.min(
      remainingAmount,
      totalStripeRefundsAvailableAmount
    );

    // For Stripe either have the option of 0 or the max amount
    // also don't want any negative values
    const stripeRefundOptions = [0, maxStripeRefund].filter(
      (val, idx, arr) => arr.indexOf(val) === idx && val >= 0
    );

    for (const stripeRefundAmount of stripeRefundOptions) {
      const creditOnAccountAmount = remainingAmount - stripeRefundAmount;
      if (creditOnAccountAmount === 0 || canDoAccountCreditRefund) {
        const accountCreditItems = getAccountCreditItems(
          creditOnAccountAmount,
          existingCreditItemsUsed,
          accountCreditExpiry
        );
        const accountCreditItemsTotal = accountCreditItems.reduce(
          (acc, item) => acc + item.amount,
          0
        );

        // TODO: I think we could also add the adjustments and deduct pass credit amount to the value here.
        // I think the only place this is used is in getTotalAmountToBeRefunded() in the BookingItemsToCancelSummary
        // component which does adds the adjustments and deducts pass credit amount.
        const refundTotal = totalAmountWithFees - bookingBalance;

        // check total of account credit items matches the amount
        // (some could be expired so not used)
        if (accountCreditItemsTotal === creditOnAccountAmount) {
          refundOptions.push({
            passCredit: passDebitLineItem
              ? getPassCreditItems(passDebitLineItem, step)
              : [],
            stripe: getStripeItems(stripeRefundAmount, stripeRefundsAvailable),
            creditOnAccount: accountCreditItems,
            feeAmount: feeAmount,
            refundTotal,
            passDebitAdjustment,
            discountAdjustment
          });
        }
      }
    }
  }

  return refundOptions;
};

export const getAccountCreditItemWithShortestExpiry = (
  cancellationRulePerLineItems: CancellationPolicyRulePerLineItem[]
): Date => {
  let shortestDate: Date | null = null;

  for (const cancellationRule of cancellationRulePerLineItems) {
    if (
      !cancellationRule.accountCreditExpiresAfter?.amount ||
      !cancellationRule.accountCreditExpiresAfter?.amount
    ) {
      continue;
    }

    // Set the date based on the expiry amount and unit
    const expiryDate = add(new Date(), {
      [cancellationRule.accountCreditExpiresAfter.unit as string]:
        cancellationRule.accountCreditExpiresAfter.amount
    });

    // If there is no shortest date set yet or this date is shorter, update the shortest
    if (!shortestDate || expiryDate < shortestDate) {
      shortestDate = expiryDate;
    }
  }

  return shortestDate as Date;
};
